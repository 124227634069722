class Utilities {

  // Get previous sibling that matches selector
  static getPreviuosSibling(elem, selector) {
    let sibling = elem.previousElementSibling;
    let match = null;
    let matchesFn = null;

    // find vendor prefix
    [
      'matches',
      'webkitMatchesSelector',
      'mozMatchesSelector',
      'msMatchesSelector',
      'oMatchesSelector'
    ].some((fn) => {
      if (typeof document.body[fn] === 'function') {
        matchesFn = fn;
        return true;
      }
      return false;
    });

    if (!selector) {
      match = sibling;
    } else {
      while (sibling) {
        if (sibling[matchesFn](selector)) {
          match = sibling;
          break;
        } else {
          sibling = sibling.nextElementSibling;
        }
      }
    }

    return match;
  }

  // set cookie
  static setCookie(name, value, days) {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
  }

  // get cookie
  static getCookie(name) {
    const cookieName = name;
    const re = new RegExp(`${cookieName}=([^;]+)`);
    const value = re.exec(document.cookie);

    return (value != null) ? unescape(value[1]) : null;
  }
}

export default Utilities;

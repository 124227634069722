import Flickity from 'flickity';
import Utilities from './utilities';

/**
* Slider with different kind of post type links
*/
class CardSlider {
  static init() {

    const cardSlider = document.querySelectorAll('.js-card-slider');

    if (!cardSlider || cardSlider.length === 0) {
      return;
    }

    [ ...cardSlider ].forEach((slider) => {
      this.sliderConfig(slider);
    });

  }

  static sliderConfig(slider) {
    const _ = new Flickity(slider, {
      cellAlign: 'left',
      contain: true,
      freeScroll: true,
      pageDots: false,
      imagesLoaded: true,
      on: {
        scroll: function(progress) {
          const intro = Utilities.getPreviuosSibling(slider, '.o-card-slider__intro');
          const next = slider.querySelector('.next');
          const previous = slider.querySelector('.previous');

          if (progress > 0.01) {
            intro.classList.add('o-card-slider__intro--hidden');
            next.classList.add('flickity-button--moved');
            previous.classList.add('flickity-button--moved');
            slider.classList.add('started');
          } else {
            intro.classList.remove('o-card-slider__intro--hidden');
            next.classList.remove('flickity-button--moved');
            previous.classList.remove('flickity-button--moved');
            slider.classList.remove('started');
          }

          if (progress >= 0.9) {
            slider.classList.add('finished');
          } else {
            slider.classList.remove('finished');
          }
        },
        dragStart: function() {
          document.ontouchmove = function(e) {
            e.preventDefault();
          };
        },
        dragEnd: function() {
          document.ontouchmove = function() {
            return true;
          };
        }
      }
    });

    const buttons = slider.querySelectorAll('.flickity-button');

    [ ...buttons ].forEach((button) => {
      button.innerHTML = '<svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Start" transform="translate(-66.000000, -6242.000000)" fill="#000000" fill-rule="nonzero"><g id="Produktsnurra" transform="translate(0.000000, 5669.000000)"><g id="back-button" transform="translate(66.000000, 573.000000)"><g id="Shape"><path d="M25.0001141,50 C38.7851533,50 50,38.7849017 50,24.9998859 C50,11.2150983 38.7851533,0 25.0001141,0 C11.2150748,0 0,11.2150983 0,24.9998859 C0,38.7849017 11.2150748,50 25.0001141,50 Z M25.0001141,3.42229787 C36.8980639,3.42229787 46.5778678,13.1019252 46.5778678,24.9998859 C46.5778678,36.8978467 36.898292,46.5777021 25.0001141,46.5777021 C13.1021643,46.5777021 3.42258849,36.8978467 3.42258849,24.9996578 C3.42258849,13.1021533 13.1021643,3.42229787 25.0001141,3.42229787 Z"></path><path d="M22.1953617,35.3430877 C22.8591042,36.0178281 23.9352781,36.0175977 24.598794,35.3430877 C25.2627631,34.6678864 25.2627631,33.5737378 24.5985674,32.8985365 L18.0676491,26.2573673 L36.9798922,26.2555238 C37.9185134,26.2552933 38.6792453,25.4816924 38.6792453,24.526732 C38.6792453,23.5722324 37.9182868,22.798862 36.9796656,22.798862 L18.0667427,22.8007055 L24.5992472,16.1581537 C25.2629897,15.4831829 25.2629897,14.3885733 24.5992472,13.7138329 C24.2672626,13.3764627 23.8323968,13.2075472 23.3973044,13.2075472 C22.9624386,13.2075472 22.5275728,13.3764627 22.1955883,13.7136025 L12.7620145,23.3065304 C12.4431733,23.6305348 12.2641509,24.0699917 12.2641509,24.5285755 C12.2641509,24.9871593 12.4433999,25.4263858 12.7622411,25.7510816 L22.1953617,35.3430877 Z" id="Path"></path></g></g></g></g></g></svg>';
    });
  }
}

export default CardSlider;

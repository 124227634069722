import Flickity from 'flickity';

/**
* Carousel with customer quotes
*/
class QuoteCarousel {
  static init() {

    const quoteCarousel = document.querySelectorAll('.js-quote-carousel');

    if (!quoteCarousel || quoteCarousel.length === 0) {
      return;
    }

    [ ...quoteCarousel ].forEach((carousel) => {
      this.carouselConfig(carousel);
    });

  }

  static carouselConfig(carousel) {
    const _ = new Flickity(carousel, {
      cellAlign: 'left',
      contain: true,
      freeScroll: false,
      prevNextButtons: false,
      pageDots: true,
      imagesLoaded: true,
      autoPlay: 5000,
      on: {
        dragStart: function() {
          document.ontouchmove = function(e) {
            e.preventDefault();
          };
        },
        dragEnd: function() {
          document.ontouchmove = function() {
            return true;
          };
        }
      }
    });
  }
}

export default QuoteCarousel;

import $ from 'jquery';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';


class DownloadMultipleFiles {
    static init() {

        /**
         * Reset the message.
         */
        function resetMessage() {
            $('#result')
            .removeClass()
            .text('');
        }

        /**
         * show a successful message.
         * @param {String} text the text to show.
         */
        function showMessage(text) {
            resetMessage();
            $('#result')
            .addClass('alert alert-success')
            .text(text);
        }

        /**
         * show an error message.
         * @param {String} text the text to show.
         */
        function showError(text) {
            resetMessage();
            $('#result')
            .addClass('alert alert-danger')
            .text(text);
        }

        /**
         * Update the progress bar.
         * @param {Integer} percent the current percent
         */
        function updatePercent(percent) {
            $('#progress_bar').removeClass('hide')
            .find('.progress-bar')
            .attr('aria-valuenow', percent)
            .css({ width: percent + '%' });
        }

        if (!JSZip.support.blob) {
            showError('This demo works only with a recent browser !');
            return;
        }

            var Promise = window.Promise;
            if (!Promise) {
                Promise = JSZip.external.Promise;
            }

            /**
             * Fetch the content and return the associated promise.
             * @param {String} url the url of the content to fetch.
             * @return {Promise} the promise containing the data.
             */

             function urlToPromise(url) {
                return new Promise(function(resolve, reject) {
                JSZipUtils.getBinaryContent(url, function(err, data) {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(data);
                    }
                    });
                });
            }

            $(function() {
                $('input[name="checker"]').change(function() {
                  var len = $('input[name="checker"]:checked').length;
                  if (len === 0) {
                    $('input[type="submit"]').prop('disabled', true);
                  } else {
                    $('input[type="submit"]').removeAttr('disabled');
                  }
                });
                $('input[name="checker"]').trigger('change');
              });

            $('input[name="checker"]').on('click', function() {
                var len = $('input[name="checker"]:checked').length;
                if (!len === 0) {
                    $('.o-downloads__button').removeAttr('disabled', true);
                    console.log('hej');
                    console.log(len);
                }
            });

            $('#download_form').on('submit', function() {

                resetMessage();

                var zip = new JSZip();

                // find every checked item
                $(this).find(':checked').each(function() {
                    var $this = $(this);
                    var url = $this.data('url');
                    var filename = url.replace(/.*\//g, '');
                    zip.file(filename, urlToPromise(url), { binary: true });
                });

                // when everything has been downloaded, we can trigger the dl
                zip.generateAsync({ type: 'blob' }, function updateCallback(metadata) {
                    var msg = 'progression : ' + metadata.percent.toFixed(2) + ' %';
                    if (metadata.currentFile) {
                        msg += ', current file = ' + metadata.currentFile;
                    }
                    showMessage(msg);
                    updatePercent(metadata.percent | 0);
                })
                .then(function callback(blob) {

                    // see FileSaver.js
                    saveAs(blob, 'Downloads.zip');

                    showMessage('Filerna har laddats ner!');
                }, function(e) {
                    showError(e);
                });

                return false;
            });
    }
}
export default DownloadMultipleFiles;

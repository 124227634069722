import Utilities from './utilities';

/**
* Checks if user has approved cookies and shows cookie notice if not
*/
class CookieNotice {
  static init() {
    const cookieBar = document.querySelector('.js-cookie-bar');
    const approveCookies = document.querySelector('.js-approve-cookies');
    const cookieValue = Utilities.getCookie('cookies_approved');

    if (cookieValue !== 'true' || cookieValue == null) {
      cookieBar.style.display = 'block';
    }

    approveCookies.addEventListener('click', () => this.hideNotice(cookieBar), true);
  }

  static hideNotice(cookieBar) {
    const notificationElement = cookieBar;

    Utilities.setCookie('cookies_approved', true, 365);
    notificationElement.style.display = 'none';
  }
}

export default CookieNotice;

import $ from 'jquery';

/**
* Toggle overlay
*/
class Overlay {
  static init() {
    const $this = this;

    $('.o-navigation__button--menu').on('click', function(e)  {
      e.preventDefault();

      if (!$(this).hasClass('o-navigation__button--close')) {

        $('.o-navigation__button--close').removeClass('o-navigation__button--close');
        $(this).addClass('o-navigation__button--close');
        $('.o-main-menu__mobile-nav').toggleClass('o-main-menu__mobile-nav--visible');
        $('.o-overlay__search').removeClass('o-overlay__search--visible');
        $('.o-overlay').removeClass('o-overlay--visible');

      } else {
        $this.close();
      }
    });

    $('.o-navigation__button--search').on('click', function(e) {
      e.preventDefault();

      if (!$(this).hasClass('o-navigation__button--close')) {

        $('.o-navigation').addClass('o-navigation--white');
        $('.o-navigation__button--close').removeClass('o-navigation__button--close');
        $(this).addClass('o-navigation__button--close');
        $('.o-overlay').addClass('o-overlay--visible');
        $('.o-main-menu__mobile-nav').removeClass('o-main-menu__mobile-nav--visible');
        $('.o-overlay__search').addClass('o-overlay__search--visible');

      } else {
        $this.close();
      }
    });
  }

  static close() {
    $('.o-navigation').removeClass('o-navigation--white');
    $('.o-navigation__button--close').removeClass('o-navigation__button--close');
    $('.o-main-menu__mobile-nav').removeClass('o-main-menu__mobile-nav--visible');
    $('.o-overlay__search').removeClass('o-overlay__search--visible');
    $('.o-overlay').removeClass('o-overlay--visible');
  }
}

export default Overlay;

import $ from 'jquery';

/**
* Off canvas navigation
*/
class MainMenu {
  static init() {

    // Add arrows if has children
    const mobList = $('.o-main-menu__mobile-list');
    const desktopList = $('.o-main-menu__list');

    desktopList.find('li.menu-item-has-children')
    .append('<span class="sub-toggler" tabindex="0"><span>');

    mobList.find('li.menu-item-has-children')
    .append('<span class="sub-toggler collapsed" tabindex="0"><span>');

    mobList.find('li.current-menu-parent .sub-toggler')
    .removeClass('collapsed');

    mobList.find('li.menu-item-has-children.current-menu-item .sub-toggler')
    .removeClass('collapsed');

    mobList.find('li.menu-item-has-children.current-menu-item ul.sub-menu')
    .addClass('is-visible');

    mobList.find('li.menu-item-has-children.current-menu-parent ul.sub-menu')
    .addClass('is-visible');

    $('.sub-toggler').on('keypress click', function() {
      var $this = $(this),
      subMenu = $this.siblings('ul');
      $('.o-main-menu__list > li span.collapsed').not($this).removeClass('collapsed');
      $('.o-main-menu__list > li ul.is-visible').not(subMenu).removeClass('is-visible');
      $('.o-main-menu__list > li ul.is-visible').not(subMenu).removeClass('is-visible');

      $(this).toggleClass('collapsed');
      $(this).prev('ul').toggleClass('is-visible');
    });
  }
}

export default MainMenu;

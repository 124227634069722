import Overlay from './modules/overlay';
import MainMenu from './modules/main-menu';
import CardSlider from './modules/card-slider';
import QuoteCarousel from './modules/quote-carousel';
import DownloadsToggle from './modules/downloads-toggle';
import CookieNotice from './modules/cookie-notice';
import DownloadMultipleFiles from './modules/download-multiple-files';

document.addEventListener('DOMContentLoaded', () => {

  // init js modules
  Overlay.init();
  MainMenu.init();
  CardSlider.init();
  QuoteCarousel.init();
  DownloadsToggle.init();
  CookieNotice.init();
  DownloadMultipleFiles.init();


  // eslint-disable-next-line no-console
  console.log(`%c

    @@@@@@@@@@@@@@ @@@@@@@@@@@@@
    @@@@@@@ @@@@@@ @@@@@@@@@@@@@
          @ @      @@@@      @@@
          @ @      @@@@@@@@@@@@@@@
          @ @      @@@@@@@@@@@@@@@
          @ @      @@@@       @@@@
          @ @      @@@@       @@@@

                   @@@@@@@@@@@@@@@ @@@@@@@@@@@@@@
                   @@@@@@@@@@@@@@@ @@@@@@ @@@@@@@
                   @@@@       @@@@ @@@  @ @   @@@
                   @@@@       @@@@ @@@  @ @   @@@
                   @@@@       @@@@ @@@  @ @   @@@
                   @@@@@@@@@@@@@@@ @@@  @ @   @@@
                   @@@@@@@@@@@@@@@ @@@  @ @   @@@

                   @@@@@@@@@@@@@@@
                   @@@@       @@@@
                   @@@@@@@@@@@@@@@
                   @@@@@@@@@@@@@@@
                   @@@@       @@@@
                   @@@@@@@@@@@@@@@
                   @@@@@@@@@@@@@@@

  `, 'font-family:monospace; font-size: 8px; line-height: 4px; color:#3F8D77;');

  // eslint-disable-next-line no-console
  console.log('Developed by www.tromb.com');
});

import $ from 'jquery';

/**
* Toggle accordions on downloads page
*/
class DownloadsToggle {
  static init() {
    const faqToggle = $('.js-faq-toggle');

    if (!faqToggle || faqToggle.length === 0) {
      return;
    }

    // If the question-down-arrow is clicked, show the question
    $('.js-faq-toggle')
      .on('click', function(e) {
        e.preventDefault();

        $(this)
          .siblings('.o-downloads__item')
          .slideToggle('fast');

        $(this)
          .toggleClass('o-downloads__item-arrow--open');

        $(this)
          .parent('.o-downloads__item-accordion')
          .toggleClass('o-downloads__item-accordion--open');

      }
    );

    $('.o-downloads__item-accordion:first-of-type')
      .addClass('o-downloads__item-accordion--open');

    $('.o-downloads__item-accordion:first-of-type button:first-of-type')
      .addClass('o-downloads__item-arrow--open');

    $('.o-downloads__item-title')
      .on('click', function(e) {
        e.preventDefault();

        $(this)
          .parent('.o-downloads__item-top')
          .siblings('.o-downloads__item')
          .slideToggle('fast');

        $(this)
          .parent('.o-downloads__item-top')
          .siblings('.o-downloads__item-arrow')
          .toggleClass('o-downloads__item-arrow--open');

        $(this)
          .parent('.o-downloads__item-top')
          .parent('.o-downloads__item-accordion')
          .toggleClass('o-downloads__item-accordion--open');
      }
    );

    // Setting variables
    var downloadsHolder = $('.o-downloads__items'),
    downloadsHolderOffsetTop = parseInt(downloadsHolder.offset().top) - 90;

    $(window).scroll(function() {
      var catChangeReach = $(window).scrollTop() + 110;

      // If scrollTop reaches a certain level it sets the category menu to fixed so it follows the scroll.
      if (
        $(this).scrollTop() >= downloadsHolderOffsetTop &&
        !$('.o-downloads__categories').hasClass('fixed')
      ) {
        $('.o-downloads__categories')
          .addClass('o-downloads__categories--fixed');
      } else {
        $('.o-downloads__categories')
          .removeClass('o-downloads__categories--fixed');
      }

          // Here we want to know change the menu active depending on where the scroll is
      $('.o-downloads__item-group').each(function() {
        var elementReachTop = $(this).offset().top,
        elementReachBottom = $(this).offset().top + $(this).height(),
        currentCategory = $(this).data('category-inline-slug');

        if (
          catChangeReach >= elementReachTop &&
          catChangeReach <= elementReachBottom
        ) {

          // Change the indicator to active if the right category is reached

          if (typeof currentCategory != 'undefined') {
            $('#slug-' + currentCategory)
              .addClass('o-downloads__categories-list-item--active')
              .children('.o-downloads__categories-list-item-indicator')
              .addClass('o-downloads__categories-list-item-indicator--active');
          }
        } else {
          if (typeof currentCategory != 'undefined') {

            // Remove the indicator's active if the right category is left

            $('#slug-' + currentCategory)
              .removeClass('o-downloads__categories-list-item--active')
              .children('.o-downloads__categories-list-item-indicator')
              .removeClass('o-downloads__categories-list-item-indicator--active');
          }
        }
      });
    });

    $('.o-downloads__categories-list-item')
      .on('click', '.js-downloads-category', function(e) {

        // Scroll to the category chosen in the left hand menu

        e.preventDefault();

        var categorySlug = $(this).data('category-slug'),
        category = $('#' + categorySlug),
        categorySlugOffset = category.offset().top;

        $(this)
          .siblings('.o-downloads__categories-list-item-indicator--active')
          .fadeIn('fast');

        $('html, body').animate(
          {
            scrollTop: categorySlugOffset - 20
          },
          200
        );
      }
    );
  }
}

export default DownloadsToggle;
